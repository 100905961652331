import { CampaignStatePayload } from '../../../types/campaign-types';

const initialCampaignState: CampaignStatePayload = {
  loading: false,
  success: false,
  error: null,
  message: '',
  campaignId: '',
  detailsId: '',
  sharingId: '',
  auctionId: '',
  settingsId: '',
  campaigns: {
    upcoming: [],
    active: [],
    past: [],
  },
  campaignsForAdminView: [],
  campaign: {
    _id: '',
    title: '',
    subtitle: '',
    goal: 0,
    themeColor: {
      xlight: '',
      light: '',
      dark: '',
      darker: '',
      text: '',
      text2: '',
      gradient: '',
      border: '',
      border2: '',
      fill: '',
    },
    coverPhoto: '',
    coverPhotoName: '',
    maintainAspectRatio: false,
    totalCampaignRevenue: 0,
    supporters: 0,
    story: '',
    customCampaignLink: '',
    isCampaignPublished: false,
    isMoneyRaisedVisible: false,
    isTipsEnabled: false,
    campaignStatus: '',
    auction: {
      _id: '',
      settings: {
        startDate: '',
        endDate: '',
        isAuctionPublished: false,
        anonymousBidding: false,
        hasBegun: false,
        hasEnded: false,
        auctionStatus: '',
      },
      donations: [],
      items: [],
      bidders: [],
      winningBids: [],
      itemFulfillments: [],
    },
    imgPreference: '',
  },
  auctionItem: {
    _id: '',
    name: '',
    description: '',
    photos: [
      {
        _id: '',
        url: '',
        name: '',
        size: '',
      },
    ],
    instantBuyers: [],
    sellingFormat: 'auction',
    startingPrice: 1,
    buyNowPrice: 5,
    totalQuantity: 1,
    requiresShipping: false,
    shippingCosts: 0,
    currentBid: 0,
    minimumBid: 0,
    totalBids: 0,
    bidIncrement: 0,
    retailValue: 0,
    highestBidAmount: 0,
    bids: [],
    total: 0,
    topBidder: '',
    soldPrice: 0,
  },
  instantBuy: {},
  confirmedBidAmount: 0,
  type: '',
  winner: {
    _id: '',
    auctionItemPaymentStatus: '',
    hasShippingAddress: false,
    itemSoldPrice: 0,
    shipping: 0,
    totalPrice: 0,
    theme: {} as any,
    user: {
      name: '',
    },
    auctionItem: {
      _id: '',
      photos: [
        {
          _id: '',
          url: '',
          name: '',
          size: '',
        },
      ],
      name: '',
      description: '',
      instantBuyers: [],
      sellingFormat: '',
      startingPrice: 0,
      buyNowPrice: 0,
      totalQuantity: 0,
      requiresShipping: false,
      shippingCosts: 0,
      currentBid: 0,
      minimumBid: 0,
      totalBids: 0,
      bidIncrement: 0,
      retailValue: 0,
      highestBidAmount: 0,
      bids: [],
      total: 0,
      topBidder: '',
      soldPrice: 0,
    },
    customCampaignLink: '',
  },
  customCampaignLink: '',
  status: '',
  hasHandledAuctionModal: false,
  isAuctionModalOpen: false,
  campaignStatus: 'Pre-Campaign',
};

export default initialCampaignState;
